
import Vue from "vue";
//@ts-ignore
import VueSimpleRangeSlider from "vue-simple-range-slider";

//@ts-ignore
import _ from "lodash";

export default Vue.extend({
  name: "MentorcastsSearch",
  components: {
    VueSimpleRangeSlider
  },
  props: {
    filters: Object,
    oldFilter: Object,
    min_price: Number,
    max_price: Number
  },
  data() {
    return {
      filter: this.oldFilter,
      range: [0, this.max_price],
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      Deselect: {
        render: (createElement: any) =>
          createElement("b-icon", {
            attrs: {
              icon: "X"
            }
          })
      }
    };
  },
  watch: {
    range: _.debounce(function(val: any) {
      //@ts-ignore
      (this as any).filter.price = val;
      //@ts-ignore
      this.handleSearch();
    }, 100)
  },
  methods: {
    handleSearch() {
      this.$emit("search", this.filter);
    }
  }
});
